import React from 'react';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ChatModal from './chatModal';

export default (props) => {
    return (
        <div className="container-fluid">
            <ChatModal />
            <Navbar />
            {props.children}
            <Footer />
        </div>
    )
}
