import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import arrowRight from '../img/arrowRight.svg';
import modalSvg from '../img/modal/modal.svg';
import { ModalContext } from '../context/modalContext';
import { Link, graphql, useStaticQuery } from 'gatsby';

export default () => {

	const data = useStaticQuery(graphql`
  query {
    fennecJson {
      letsChat {
       data1,
       data2,
       data3,
       data4,
       data5,
       data6
      }
    }
  }
`)

	// const { show, onHide, step, setStep, hideModal } = useContext(ModalContext);
	const { show, onHide } = useContext(ModalContext);

	// const handleStep1 = () => {
	// 	setStep(2);
	// }
	// const handleStep2 = () => {
	// 	setStep(3);
	// }



	return (

		<Modal
			show={show}
			onHide={() => onHide()}
			aria-labelledby="example-custom-modal-styling-title"
			centered
		>
				{/* {
					step === 1 && ( */}
						<>
						<Modal.Header className="relative">
							<button type="button" className="modal-close-btn" onClick={() => onHide()}>
								<span><img src={modalSvg} className="modalSvg" width="20px" height="20px" alt="" /></span>
							</button>
						</Modal.Header>
						<Modal.Body>
							<h2 className="modalH2 font-f6 font-sz-75 animated slideInRight" data-1 >{data.fennecJson.letsChat.data1}</h2>
							<p className="modalP1 font-f4 font-sz-20 animated2 fadeIn" data-2 >{data.fennecJson.letsChat.data2}</p>
							<div className="row bottomRow">
								<div className="col-md-6 col-lg-5">
									{/* <p className="modalP2 font-f6 font-sz-22 animated2 fadeIn" onClick={handleStep1} data-3 >{data.fennecJson.letsChat.data3}<img src={arrowRight} alt="" className="arrowRight" /></p> */}
									<a className="modalP2 font-f6 font-sz-22 animated2 fadeIn" data-3 href="mailto:hello@fennecventures.com" >{data.fennecJson.letsChat.data3}<img src={arrowRight} alt="" className="arrowRight" /></a>
									<p className="modalP3 font-more font-sz-16 animated2 fadeIn mt-3" data-4 >{data.fennecJson.letsChat.data4}</p>
								</div>
								<div className="col-md-6 col-lg-5">
									{/* <p className="modalP2 font-f6 font-sz-22 animated2 fadeIn" onClick={handleStep2} data-5 >{data.fennecJson.letsChat.data5}<img src={arrowRight} alt="" className="arrowRight" /></p> */}
									<Link to="/typeForm/" onClick={() => onHide()} ><p className="modalP2 font-f6 font-sz-22 animated2 fadeIn" data-5 >{data.fennecJson.letsChat.data5}<img src={arrowRight} alt="" className="arrowRight" /></p></Link>
									<p className="modalP4 font-more font-sz-16 animated2 fadeIn" data-6 >{data.fennecJson.letsChat.data6}</p>
								</div>
							</div>
						</Modal.Body>
						</>
					{/* )
				}
				{
					step === 2 && (
						<>
						<Modal.Header className="relative">
							<button type="button" className="modal-close-btn" onClick={() => onHide()}>
								<span><img src={backSvg} className="modalSvg" width="20px" height="20px" alt="" /></span>
							</button>
						</Modal.Header>
						<Modal.Body>
							<h2 className="modalH2 font-f6 font-sz-75 animated slideInLeft" data-7 >{data.fennecJson.letsChat.data7}</h2>
							<p className="modalP1 font-f4 font-sz-20 animated2 fadeIn" data-8 >{data.fennecJson.letsChat.data8}</p>
							<form className="form1 px-1 px-lg-5">
								<div className="form row mb-3 animated2 fadeIn">
									<div className="col-12 col-lg-6 mb-3 mb-lg-0">
										<input type="email" className="form-control emailIcon" placeholder="Email" required />
									</div>
									<div className="col-12 col-lg-6">
										<input type="text" className="form-control phoneIcon" placeholder="Phone Number" required />
									</div>
								</div>
								<div className="form row mb-3 animated2 fadeIn">
									<div className="col">
										<textarea className="form-control" placeholder="Message" required />
									</div>
								</div>
								<button type="submit" className="formBtn font-f5 font-sz-20  mt-1 mb-3 animated slideInUp">SEND</button>
							</form>
						</Modal.Body>
						</>
					)
				}
				{
					step === 3 && (
						<>
						<Modal.Header className="relative">
							<button type="button" className="modal-close-btn" onClick={() => onHide()}>
								<span><img src={backSvg} className="modalSvg" width="20px" height="20px" alt="" /></span>
							</button>
						</Modal.Header>
						<Modal.Body>
							<div className="step3row">
								<h2 className="modalH2 font-f6 font-sz-44 animated slideInRight" data-9 >{data.fennecJson.letsChat.data9}</h2>
								<p className="modalP1 font-more font-sz-20 animated2 fadeIn" data-10 >{data.fennecJson.letsChat.data10}</p>
							</div>
							<form className=" px-1 px-lg-5">
								<div className="form row mb-3 animated2 fadeIn">
									<div className="col mb-3 mb-lg-0">
										<input type="email" className="form-control emailIcon" placeholder="Email" required />
									</div>
								</div>
								<div className="form row mb-3 animated2 fadeIn">
									<div className="col">
										<textarea className="form-control" placeholder="Message" required />
									</div>
								</div>
								<div className="row">
									<p className="attachP font-more font-sz-20 pl-3 animated2 fadeIn" data-11 >{data.fennecJson.letsChat.data11}</p>
								</div>
								<FilePond  allowMultiple={true}/>
								<div className="row my-3 pl-5">
									<div className="custom-control custom-checkbox mb-3 animated2 fadeIn">
										<input type="checkbox" className="custom-control-input" id="customCheck"/>
										<label className="custom-control-label font-more font-sz-20 text-left" for="customCheck" data-12 >{data.fennecJson.letsChat.data12}<Link className="privacyLink" to="/privacyPolicy/" onClick={() => hideModal()} >privacy policy.</Link></label>
									</div>
								</div>
								<button type="submit" className="formBtn font-f5 font-sz-20 mt-1 mb-3 animated slideInUp">SEND</button>
							</form>
						</Modal.Body>
						</>
					)
				} */}
		</Modal>
	)
}

// const data = useStaticQuery(graphql`
// query {
// 	fennecJson {
// 		letsChat {
// 		 data1,
// 		 data2,
// 		 data3,
// 		 data4,
// 		 data5,
// 		 data6,
// 		 data7,
// 		 data8,
// 		 data9,
// 		 data10,
// 		 data11,
// 		 data12
// 		}
// 	}
// }
// `)