import React, { useContext, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logoSrc from '../img/fennecLogo.svg';
import { Link } from 'gatsby';
import { ModalContext } from '../context/modalContext';
// import { HamburgerMenu } from 'react-hamburger-menu';

export default () => {

	const { onHide } = useContext(ModalContext)
	const [expanding, setExpanding] = useState(false);

	if (typeof window !== "undefined") {
		require("smooth-scroll")('[data-scroll]')
	}
	const activeStyles = {
		fontFamily: "proxima nova f5"
	}

	const handleClick = () => {
		if (window.innerWidth > 991) {
			return;
		}
		setExpanding(!expanding);
		if(typeof document !== `undefined`){
			var hamburger = document.querySelector(".hamburger");
		}
		hamburger.classList.toggle("is-active");
	}


	return (
		<Navbar collapseOnSelect fixed="top" expand="lg" expanded={expanding} bg="white" variant="light" onToggle={handleClick}>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" className="hamburger hamburger--3dy d-inline d-lg-none" type="button">
					<span className="hamburger-box">
						<span className="hamburger-inner"></span>
					</span>
			</Navbar.Toggle>
			<Navbar.Brand>
				<Link to="/"><img src={logoSrc} alt="" className="navbarBrandLogo d-inline-block align-top" /></Link>
			</Navbar.Brand>
			<Navbar.Collapse id="responsive-navbar-nav" className="miniNav">
				<Nav className="middleNav">
					<Link to="/#whatwedo" className="nav-link font-f5 font-sz-18" activeStyle={activeStyles} data-scroll onClick={handleClick}>What we do</Link>
					<Link to="/#techforequity" className="nav-link font-f5 font-sz-18" activeStyle={activeStyles} data-scroll onClick={handleClick}>Tech for Equity</Link>
					<Link to="/#cashinvestment" className="nav-link font-f5 font-sz-18" activeStyle={activeStyles} data-scroll onClick={handleClick}>Cash investment</Link>
					<Link to="/#portfolio" className="nav-link font-f5 font-sz-18" activeStyle={activeStyles} data-scroll onClick={handleClick}>Portfolio</Link>
				</Nav>
				<Nav className="lastNav">
					<Link to="/howItWorks/" className="nav-link font-f3 font-sz-18" activeStyle={activeStyles}>How it works</Link>
					<Link to="/about/" className="nav-link font-f3 font-sz-18" activeStyle={activeStyles}>About us</Link>
					<p className="nav-link font-f5 font-sz-18" style={{ margin: "0", cursor: "pointer" }} onClick={() => { onHide(true); handleClick() }}>Let's chat</p>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}
