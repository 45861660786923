import React, { useContext } from 'react';
import logoSrc from '../img/footerLogo.svg';
import {Link} from 'gatsby';
import { ModalContext } from '../context/modalContext';

export default () => {

    const { onHide } = useContext(ModalContext);
    
    return(
        <footer>
            <div className="row justify-content-around footer text-center text-md-left">
                <div className="col-12 col-lg-2 text-center text-lg-right">
                    <img src={logoSrc} alt="" width="90" height="50" className="d-inline-block footerLogo"/>
                </div>
                <div className="col-12 col-md-4 col-lg-2 text-lg-left">
                    <Link to="/#whatwedo" data-scroll><h5 className="col11 font-f5 font-sz-16">HOME</h5></Link>
                    <Link to="/#techforequity" data-scroll><h5 className="col12 font-f5 font-sz-16">TECH FOR EQUITY</h5></Link>
                    <Link to="/#whatIsIt" data-scroll><p className="font-f4 font-sz-16">What is it?</p></Link>
                    <Link to="/#whyChooseUs" data-scroll><p className="font-f4 font-sz-16">Why choose us?</p></Link>
                    <Link to="/#cashinvestment" data-scroll><h5 className="col13 font-f5 font-sz-16">Cash Investment</h5></Link>
                    <Link to="/#howWeWork" data-scroll><p className="font-f4 font-sz-16">How we work</p></Link>
                    <Link to="/#whatWeFocusOn" data-scroll><p className="font-f4 font-sz-16">What we focus on</p></Link>
                </div>
                <div className="col-12 col-md-4 col-lg-2 text-lg-left">
                    <Link to="/#portfolio" data-scroll><h5 className="col21 font-f5 font-sz-16">Investment Portfolio</h5></Link>
                    <a href="https://www.snappcard.com/home" target="_blank" rel="noopener noreferrer" className="font-f4 font-sz-16"><p>SnappCard</p></a>
                    <a href="https://falcon9.io/" target="_blank" rel="noopener noreferrer" className="font-f4 font-sz-16"><p>Falcon9</p></a>
                    <a href="https://www.muslim3d.com/" target="_blank" rel="noopener noreferrer" className="font-f4 font-sz-16"><p>Muslim 3D</p></a>
                    <a href="http://www.typoman.net/"  target="_blank" rel="noopener noreferrer" className="font-f4 font-sz-16"><p>Typoman</p></a>
                    <Link to="/howItWorks/#HowItWorks" data-scroll><h5 className="col22 font-f5 font-sz-16">HOW IT WORKS</h5></Link>
                    <Link to="/howItWorks/#introduceYourself" data-scroll><p className="font-f4 font-sz-16">Introduce yourself</p></Link>
                    <Link to="/howItWorks/#readOurProposal" data-scroll><p className="font-f4 font-sz-16">Read our proposal</p></Link>
                    <Link to="/howItWorks/#contract" data-scroll><p className="font-f4 font-sz-16">Contract</p></Link>
                    <Link to="/howItWorks/#startOfWork" data-scroll><p className="font-f4 font-sz-16">Start of work</p></Link>
                </div>
                <div className="col-12 col-md-4 col-lg-2 text-lg-left">
                    <Link to="/about/#whyFennec" data-scroll><h5 className="col31 font-f5 font-sz-16">ABOUT US</h5></Link>
                    <Link to="/about/#whyFennec" data-scroll><p className="font-f4 font-sz-16">Why Fennec</p></Link>
                    <Link to="/about/#resource" data-scroll><p className="font-f4 font-sz-16">Resource Table</p></Link>
                    <Link to="/about/#team" data-scroll><p className="font-f4 font-sz-16">The Team</p></Link>
                    <h5 className="col32 font-f5 font-sz-16" style={{cursor:"pointer"}} onClick={() => onHide(true)}>let's talk</h5>
                    <a href="mailto:info@alpha-apps.ae" className="font-f4 font-sz-16" style={{cursor:"pointer"}}>Say Hi</a>
                    <Link to="/typeForm/"><p className="mt-3 font-f4 font-sz-16" style={{cursor:"pointer"}}>Work with us</p></Link>
                </div>
            </div>
        </footer>
    )
}